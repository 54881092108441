import React, { useEffect, useState } from "react";
import { Area } from '@ant-design/plots';
import REQUESTS from "../../../api/requests";
import styles from "../index.module.scss";

const UsedDevicesProgress = ({ dateType = "year", date }) => {
  const [data, setData] = useState([]);

  const config = {
    data,
    tooltip: {
      showMarkers: true,
      shared: true,
      customContent: (title, items) => {
        const total = items.reduce((sum, item) => sum + item.data.count, 0);
        return `<div style="padding: 10px;">
                  <strong>Day: ${title} | Total: ${total}</strong>
                  ${items.map(item => `<div style="color:${item.color}; margin:10px 0; text-transform: capitalize;">${item.name}: ${item.data.count}</div>`).join('')}
                </div>`;
      },
    },
    xField: dateType === "year" ? 'month' : 'day',
    yField: 'count',
    seriesField: 'name',
  };

  const getChart = () => {
    const query = {
      data_type: dateType,
      date,
      type: "platform",
    };

    // Corrected callback name
    const callback = (data) => {
      let newData = [];

      if (Array.isArray(data)) {
        // Format data for month or week
        if (dateType === 'month' || dateType === 'week') {
          newData = data.map((obj) => ({
            ...obj,
            count: parseInt(obj.count, 10),
            day: obj.day.toString(),
          }));
        } else {
          newData = data.map((obj) => ({
            ...obj,
            count: parseInt(obj.count, 10),
          }));
        }
      } else {
        // Data from platforms
        for (let platform in data) {
          for (let value of data[platform]) {
            if (dateType === 'month' || dateType === 'week') {
              newData.push({
                name: platform,
                count: parseInt(value.count, 10),
                day: value.day.toString(),
              });
            } else {
              newData.push({
                name: platform,
                count: parseInt(value.count, 10),
                month: value.month,
              });
            }
          }
        }

        // Sort by day or month
        newData = newData.sort((a, b) => {
          return dateType === 'year' ? a.month - b.month : a.day - b.day;
        });
      }

      setData(newData);
    };

    const errorCallback = (error) => {
      console.error("Error fetching chart data:", error);
    };

    REQUESTS.STATISTICS.DEVICE_CHART(callback, errorCallback, query);
  };

  useEffect(() => {
    getChart();
  }, [date, dateType]);

  return (
    <div className={styles["container"]}>
      <h2 className={styles["title"]}>Devices</h2>
      <Area {...config} style={{ height: 350 }} />
    </div>
  );
};

export default UsedDevicesProgress;
