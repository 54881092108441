import React, { useEffect, useState } from 'react';
import { Table, Modal, DatePicker, Input, Button, Tooltip, Space, Radio, Select, notification } from 'antd';
import axios from 'axios';
import { DollarOutlined, RedoOutlined, CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import pLimit from 'p-limit';
import InitialParams from '../../components/InitialParams';
import getMyDate from '../../components/getMyDate';
import macMaskInput from '../../components/macMaskInput';
import DeviceManagerDrawer from '../deviceManager/components/DeviceManagerDrawer';
import REQUESTS from '../../api/requests';
import { useSelector } from 'react-redux';
import { selectTranslation } from '../../features/Translation/TranslationSlice';
import TEXT from '../../config/text';
import classes from './index.module.scss';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import CONSTANTS from '../../config/index';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { confirm } = Modal;

function PaymentHistory() {
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(null);
    const [total, setTotal] = useState();
    const [limit, setLimit] = useState(100);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [date, setDate] = useState("");
    const [sort, setSort] = useState(["id", "DESC"]);
    const [search, setSearch] = useState({ comment: "" });
    const getLast12Digits = (id) => id.toLowerCase().slice(-12);
    const [searchParams] = useSearchParams();
    const translation = useSelector(selectTranslation);
    const navigate = useNavigate();

    InitialParams(setLimit, setCurrentPage, setSearch, setSort);

    const goToPosts = (params) =>
        navigate({
            search: `?${createSearchParams(params)}`,
        });

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <Input
                allowClear
                autoFocus
                placeholder="Type text here"
                value={selectedKeys[0]}
                onChange={(e) => {
                    setSelectedKeys(e.target.value ? [e.target.value] : []);
                    confirm({ closeDropdown: false });
                }}
            />
        ),
        filterIcon: () => <SearchOutlined />,
    });

    const handleStatusChange = async (id, status) => {
        try {
            await axios.post(
                `${CONSTANTS.API_HOST}/api/update-status`,
                { id, status }
            );

            notification.success({
                message: 'Status Update Successful',
                description: `Status for ${id} updated to ${status}.`,
                placement: 'topLeft',
            });

            setPaymentHistory(prevHistory =>
                prevHistory.map(item =>
                    item.id === id
                        ? { ...item, status }
                        : item
                )
            );
        } catch (error) {
            console.error('Status Update Error:', error);

            notification.error({
                message: 'Status Update Error',
                description: error.response?.data?.message || 'An error occurred while updating the status.',
                placement: 'topLeft',
            });
        }
    };

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            width: "60px",
            fixed: "left",
            align: "center",
            render: (text, record, index) => (
                <div style={{ color: record.isDuplicate ? 'red' : 'inherit' }}>
                    {currentPage === 1 ? index + 1 : limit * (currentPage - 1) + index + 1}
                </div>
            ),
        },
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: `${translation["Mac"] || TEXT["Mac"]}`,
            dataIndex: "mac",
            key: "mac",
            align: "center",
            className: `${classes["row"]} cursor-pointer`,  // add a class for easier targeting
            render: (text, record) => (
                <div data-column="mac">
                    {text || "N/A"}
                </div>
            ),
            ...macMaskInput(),
        },
        {
            title: `${translation["Subscription id"] || TEXT["Subscription id"]}`,
            dataIndex: "subscription_id",
            key: "subscription_id",
            align: "center",
            render: (text, record) => (
                <div style={{ color: record.isDuplicate ? 'red' : 'inherit' }}>
                    {text || "N/A"}
                </div>
            ),
            ...getColumnSearchProps(),
        },
        {
            title: `${translation["Date"] || TEXT["Date"]}`,
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            sorter: true,
            render: (createdAt) => {
                const date = new Date(createdAt);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                const year = date.getFullYear();
                
                const hours = String(date.getHours()).padStart(2, '0'); // 24-hour format
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
            
                const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
            
                return formattedDate;
            },
            filterDropdown: () => (
                <div style={{ padding: 8 }}>
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined style={{ color: JSON.parse(searchParams.get("between"))?.createdAt ? "#1890ff" : undefined }} />
            ),
        },
        {
            title: "Method",
            dataIndex: "method",
            key: "method",
            align: "center",
            ...getColumnSearchProps(),
        },
        {
            title: `${translation["Amount"] || TEXT["Amount"]}`,
            dataIndex: "amount",
            key: "amount",
            render: (amount, record) => `${amount} ${record.currency}`,
            align: "center",
            sorter: true,
        },
        {
            title: `${translation["Status"] || TEXT["Status"]}`,
            dataIndex: "status",
            key: "status",
            align: "center",
            filters: [
                { text: "NOT CAPTURED", value: "PAID" },
                { text: "COMPLETED", value: "COMPLETED" },
                { text: "PENDING", value: "PENDING" },
                { text: "REFUNDED", value: "REFUNDED" },
            ],
            filterDropdown: ({ filters, setSelectedKeys, selectedKeys, confirm }) => (
                <>
                    <div style={{ padding: "6px 0 0 6px" }}>
                        <Radio.Group onChange={(e) => setSelectedKeys([e.target.value])} value={selectedKeys[0]}>
                            {filters.map(filteredItem => (
                                <div key={filteredItem.value}><Radio value={filteredItem.value}>{filteredItem.text}</Radio></div>
                            ))}
                        </Radio.Group>
                    </div>
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Button
                                type="link"
                                onClick={() => {
                                    setSelectedKeys([]);
                                    confirm();
                                }}
                                size="small"
                                style={{ width: 100 }}
                            >
                                Reset
                            </Button>
                            <Button
                                size="small"
                                style={{ backgroundColor: "#000", color: '#fff' }}
                                onClick={() => confirm()}
                            >
                                OK
                            </Button>
                        </Space>
                    </div>
                </>
            ),
            render: (status, record) => (
                <Select style={{ width: 150 }}
                    value={status}
                    onChange={(value) => handleStatusChange(record.id, value)}
                >
                    <Option value="PAID">NOT CAPTURED</Option>
                    <Option value="COMPLETED">COMPLETED</Option>
                    <Option value="PENDING">PENDING</Option>
                    <Option value="REFUNDED">REFUNDED</Option>
                </Select>
            ),
        },
        {
            title: 'Package',
            dataIndex: "package_name",
            align: "center",
            key: "package_name",
            ...getColumnSearchProps(),
            render: (record) => record || "N/A",
        },
        {
            title: 'Model',
            dataIndex: "device_model",
            key: "device_model",
            align: "center",
            ...getColumnSearchProps(),
            render: (record) => record || "N/A",
        },
        {
            title: 'Actions',
            key: 'action',
            align: 'center',
            render: (text, record) => {
                // Determine the disabled state for each button
                const isCaptureDisabled = record.status === 'COMPLETED' || record.status === 'REFUNDED' || record.status === 'PENDING' || record.status === 'success';
                const isRefundDisabled = record.status !== 'PAID';
                const isshowDisabled = record.status === 'PAID';
        
                // Check if any button should be visible
                const shouldShowCapture = !isCaptureDisabled;
                const shouldShowRefund = !isRefundDisabled;
                const shouldShowDisabled = !isshowDisabled;
        
                // Render buttons with tooltips based on the record method
                return (
                    <>
                        {shouldShowCapture && (
                            <Tooltip title="Capture">
                                <Button
                                    type="primary"
                                    onClick={() => captureTransaction(record.subscription_id)}
                                    disabled={isCaptureDisabled}
                                    icon={<DollarOutlined />}
                                    className={classes.buttonCapture}
                                />
                            </Tooltip>
                        )}
                        {shouldShowRefund && (
                            <Tooltip title="Refund">
                                <Button
                                    type="danger"
                                    onClick={() => refundTransaction(record.subscription_id, record.currency)}
                                    disabled={isRefundDisabled}
                                    icon={<RedoOutlined />}
                                    className={classes.buttonRefund}
                                />
                            </Tooltip>
                        )}
                        {shouldShowDisabled && (
                                <Button
                                    type="danger"
                                    disabled={true}
                                    icon={<DollarOutlined />}
                                    className={classes.buttonCapture}
                                />
                        )}
                        {shouldShowDisabled && (
                                <Button
                                    type="danger"
                                    disabled={true}
                                    icon={<RedoOutlined />}
                                    className={classes.buttonRefund}
                                />
                        )}
                    </>
                );
            },
        },        
    ];

    const getPaymentHistory = () => {
        setLoading(true);
        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            sort: JSON.stringify(sort),
            search: {},
        };

        const buildSearchQuery = () => {
            const searchQuery = {};
            Object.keys(search).forEach((key) => {
                if (search[key]) {
                    searchQuery[key] = search[key][0];
                }
            });
            return searchQuery;
        };

        query.search = JSON.stringify(buildSearchQuery());

        if (date && date[0]) {
            let from = new Date(date[0]);
            let to = new Date(date[1]);

            from.setHours(0, 0, 0, 0);
            to.setHours(0, 0, 0, 0);
            to.setDate(to.getDate() + 1);

            query.between = JSON.stringify({
                createdAt: {
                    from,
                    to,
                },
            });
        }

        goToPosts(query);

        REQUESTS.PAYMENTS.GET(query, (data) => {
            const subscriptionIds = data.rows.map(row => getLast12Digits(row.subscription_id));
            const duplicates = subscriptionIds.filter((item, index) => subscriptionIds.indexOf(item) !== index);
            const hasDuplicates = new Set(duplicates).size > 0;

            setTotal(data.count);
            if (data.rows.length === 0 && currentPage > 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
            setLoading(false);
            setPaymentHistory(data.rows.map(item => ({
                ...item,
                isDuplicate: duplicates.includes(getLast12Digits(item.subscription_id)),
            })));
        });
    };

    
    // The Refunds API allows you create and manage transaction refunds.

    const refundTransaction = async (subscription_id, currency, amount) => {
        try {
            // Ask for confirmation before proceeding with the refund
            const amountusd = 14 * 100; // Amount in cents for USD
            const currency = "USD"; // Amount in cents for USD

            const userConfirmed = await new Promise((resolve) => {
                Modal.confirm({
                    title: 'Confirm Refund',
                    content: `Are you sure you want to do a full refund for ${subscription_id}?`,
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: () => resolve(true),
                    onCancel: () => resolve(false),
                });
            });
    
            if (!userConfirmed) {
                return; // User canceled the refund
            }
    

            const response = await axios.post(
                `https://api.lahza.io/refund`,
                {
                    transaction: subscription_id,
                    amount: amountusd,
                    currency: currency,
                    customer_note: "Sorry, PAID Twice for same order.",
                    merchant_note: "The payment is duplicated for the same order"
                },
                {
                    headers: {
                        Authorization: `Bearer Xsk_live_9Yxctad9Fty2d6U6493dYZ4I0ue7D0rQw`,
                        'Content-Type': 'application/json',
                    },
                }
            );
    
            await axios.post(
                `${CONSTANTS.API_HOST}/api/update-status`,
                { subscription_id: subscription_id, status: 'REFUNDED' }
            );
    
            if (response.data.status) {
                notification.success({
                    message: 'Refund Successful',
                    description: `${subscription_id} successfully refunded.`,
                    placement: 'topLeft',
                });
    
                // Update the status in the frontend state
                setPaymentHistory((prevHistory) =>
                    prevHistory.map((item) =>
                        item.subscription_id === subscription_id
                            ? { ...item, status: 'REFUNDED' }
                            : item
                    )
                );
            } else {
                notification.error({
                    message: 'Refund Error',
                    description: response.data.message || 'An error occurred during the refund process.',
                    placement: 'topLeft',
                });
            }
        } catch (error) {
            console.error('Refund Error:', error);
    
            const errorMessage = error.response?.data?.message || 'Lahza.io API Error';
    
            notification.error({
                message: 'Refund Error',
                description: errorMessage,
                placement: 'topLeft',
            });
        }
    };
    
    

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const captureAllTransactions = async () => {
        const limiter = pLimit(5); // Limit to 5 concurrent requests
        const delayTime = 600; // Delay between each request in milliseconds
    
        try {
            // Filter transactions with status 'PAID'
            const paidTransactions = paymentHistory
            .filter(transaction => transaction.status === 'PAID')
            .reverse(); // Capture OLD transaction to NEW
            
            // Identify unique transactions and duplicates
            const transactionIds = paidTransactions.map(transaction => getLast12Digits(transaction.subscription_id));
            const duplicates = transactionIds.filter((item, index) => transactionIds.indexOf(item) !== index);
            const uniqueTransactions = paidTransactions.filter(transaction => !duplicates.includes(getLast12Digits(transaction.subscription_id)));
            const duplicateTransactions = paidTransactions.filter(transaction => duplicates.includes(getLast12Digits(transaction.subscription_id)));
            
            // Capture unique transactions
            for (const transaction of uniqueTransactions) {
                await limiter(async () => {
                    try {
                        const response = await axios.post(
                            `https://api.lahza.io/transaction/capture/${transaction.subscription_id}`,
                            {},
                            {
                                headers: {
                                    Authorization: `Bearer Xsk_live_9Yxctad9Fty2d6U6493dYZ4I0ue7D0rQw`,
                                },
                            }
                        );
                        
                        await axios.post(
                            `${CONSTANTS.API_HOST}/api/update-status`,
                            { subscription_id: transaction.subscription_id, status: 'COMPLETED' }
                        );
    
                        if (response.data.status) {
                            notification.success({
                                message: 'Capture Successful',
                                description: `${transaction.subscription_id} Successfully Captured.`,
                                placement: 'topLeft',
                            });
    
                            setPaymentHistory(prevHistory =>
                                prevHistory.map(item =>
                                    item.subscription_id === transaction.subscription_id
                                        ? { ...item, status: 'COMPLETED' }
                                        : item
                                )
                            );
                        } else {
                            notification.error({
                                message: 'Capture Error',
                                description: response.data.message || 'An error occurred during the Capture process.',
                                placement: 'topLeft',
                            });
                        }
                        await delay(delayTime);
    
                    } catch (error) {
                        console.error('Capture Error:', error);
    
                        const errorMessage = error.response?.data?.message || 'An error occurred';
    
                        notification.error({
                            message: 'Capture Error',
                            description: errorMessage,
                            placement: 'topLeft',
                        });
                    }
                });
            }
    
            // Process duplicate transactions: refund the first one, capture the rest
            const processedDuplicateIds = new Set();
    
            for (const transaction of duplicateTransactions) {
                const transactionId = getLast12Digits(transaction.subscription_id);
                if (!processedDuplicateIds.has(transactionId)) {
                    // Refund the first duplicate transaction
                    await limiter(async () => {
                        try {
                            const amountusd = 14 * 100; // Convert amount to cents
        
                            const response = await axios.post(
                                `https://api.lahza.io/refund`,
                                {
                                    transaction: transaction.subscription_id,
                                    amount: amountusd,
                                    currency: transaction.currency,
                                    customer_note: "Sorry, PAID Twice for same order.",
                                    merchant_note: "The payment is duplicated for same order"
                                },
                                {
                                    headers: {
                                        Authorization: `Bearer Xsk_live_9Yxctad9Fty2d6U6493dYZ4I0ue7D0rQw`,
                                        'Content-Type': 'application/json',
                                    },
                                }
                            );
        
                            await axios.post(
                                `${CONSTANTS.API_HOST}/api/update-status`,
                                { subscription_id: transaction.subscription_id, status: 'REFUNDED' }
                            );
        
                            if (response.data.status) {
                                notification.success({
                                    message: 'Refund Successful',
                                    description: `${transaction.subscription_id} successfully refunded.`,
                                    placement: 'topLeft',
                                });
        
                                setPaymentHistory(prevHistory =>
                                    prevHistory.map(item =>
                                        item.subscription_id === transaction.subscription_id
                                            ? { ...item, status: 'REFUNDED' }
                                            : item
                                    )
                                );
                            } else {
                                notification.error({
                                    message: 'Refund Error',
                                    description: response.data.message || 'An error occurred during the refund process.',
                                    placement: 'topLeft',
                                });
                            }
                            await delay(delayTime);
        
                        } catch (error) {
                            console.error('Refund Error:', error);
        
                            const errorMessage = error.response?.data?.message || 'Lahza.io API Error';
        
                            notification.error({
                                message: 'Refund Error',
                                description: errorMessage,
                                placement: 'topLeft',
                            });
                        }
                    });
                    processedDuplicateIds.add(transactionId);
                } else {
                    // Capture the other duplicate transactions
                    await limiter(async () => {
                        try {
                            const response = await axios.post(
                                `https://api.lahza.io/transaction/capture/${transaction.subscription_id}`,
                                {},
                                {
                                    headers: {
                                        Authorization: `Bearer Xsk_live_9Yxctad9Fty2d6U6493dYZ4I0ue7D0rQw`,
                                    },
                                }
                            );
                            
                            await axios.post(
                                `${CONSTANTS.API_HOST}/api/update-status`,
                                { subscription_id: transaction.subscription_id, status: 'COMPLETED' }
                            );
    
                            if (response.data.status) {
                                notification.success({
                                    message: 'Capture Successful',
                                    description: `${transaction.subscription_id} Successfully Captured.`,
                                    placement: 'topLeft',
                                });
    
                                setPaymentHistory(prevHistory =>
                                    prevHistory.map(item =>
                                        item.subscription_id === transaction.subscription_id
                                            ? { ...item, status: 'COMPLETED' }
                                            : item
                                    )
                                );
                            } else {
                                notification.error({
                                    message: 'Capture Error',
                                    description: response.data.message || 'An error occurred during the Capture process.',
                                    placement: 'topLeft',
                                });
                            }
                            await delay(delayTime);
    
                        } catch (error) {
                            console.error('Capture Error:', error);
    
                            const errorMessage = error.response?.data?.message || 'An error occurred';
    
                            notification.error({
                                message: 'Capture Error',
                                description: errorMessage,
                                placement: 'topLeft',
                            });
                        }
                    });
                }
            }
    
            notification.success({
                message: 'Capture and Refund Successful',
                description: 'All transactions have been processed.',
                placement: 'topLeft',
            });
    
            getPaymentHistory(); // Refresh payment history
    
        } catch (error) {
            console.error('Capture All Error:', error);
    
            const errorMessage = error.response?.data?.message || 'An error occurred';
    
            notification.error({
                message: 'Capture and Refund Error',
                description: errorMessage,
                placement: 'topLeft',
            });
        }
    };
    
    const captureTransaction = async (subscription_id) => {
        // Find the transaction in the payment history
        const transaction = paymentHistory.find(item => item.subscription_id === subscription_id);
        
        // Skip if the transaction is a duplicate
        if (transaction?.isDuplicate) {
            notification.info({
                message: 'Duplicate Transaction',
                description: `Transaction ${subscription_id} is a duplicate and will not be captured.`,
                placement: 'topLeft',
            });
            return;
        }
    
        try {
            // Capture the transaction
            const response = await axios.post(
                `https://api.lahza.io/transaction/capture/${subscription_id}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer Xsk_live_9Yxctad9Fty2d6U6493dYZ4I0ue7D0rQw`,
                    },
                }
            );
    
            notification.success({
                message: 'Capture Successful',
                description: `${subscription_id} successfully Captured.`,
                placement: 'topLeft',
            });
    
            // Update the status in the frontend state
            setPaymentHistory((prevHistory) =>
                prevHistory.map((item) =>
                    item.subscription_id === subscription_id
                        ? { ...item, status: 'COMPLETED' }
                        : item
                )
            );
    
            await axios.post(
                `${CONSTANTS.API_HOST}/api/update-status`,
                { subscription_id, status: 'COMPLETED' }
            );
    
        } catch (error) {
            console.error('Capture Error:', error);
    
            // Check if the error message indicates the transaction is already captured
            if (error.response?.data?.message.includes("Transaction already captured")) {
                // Update the status to COMPLETED
                try {
                    await axios.post(
                        `${CONSTANTS.API_HOST}/api/update-status`,
                        { subscription_id, status: 'COMPLETED' }
                    );
    
                    notification.info({
                        message: 'Already Captured',
                        description: `${subscription_id} status updated to COMPLETED.`,
                        placement: 'topLeft',
                    });
    
                    // Update the status in the frontend state
                    setPaymentHistory((prevHistory) =>
                        prevHistory.map((item) =>
                            item.subscription_id === subscription_id
                                ? { ...item, status: 'COMPLETED' }
                                : item
                        )
                    );
    
                } catch (updateError) {
                    console.error('Update Status Error:', updateError);
    
                    notification.error({
                        message: 'Update Status Error',
                        description: updateError.response?.data?.message || 'An error occurred while updating the status.',
                        placement: 'topLeft',
                    });
                }
            } else {
                const errorMessage = error.response?.data?.message || 'Lahza.io API Error';
    
                notification.error({
                    message: 'Capture Error',
                    description: errorMessage,
                    placement: 'topLeft',
                });
            }
        }
    };
    

    useEffect(() => {
        const timeout = setTimeout(() => {
            getPaymentHistory();
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [currentPage, limit, sort, search, date]);

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
        setSearch(filters);
        setSort([sorter.field ? sorter.field : "id", sorter.order === "ascend" ? "ASC" : "DESC"]);
    };

    const getDevice = (mac) => {
        if (!mac) return setCurrent(null);

        const query = {
            filter: JSON.stringify({
                mac
            })
        };
        REQUESTS.DEVICE.GET(query, (data) => {
            setCurrent(data.rows[0]);
        });
    };

    return (
        <div>
            <h1 className='table-title'>Payments History
                <Button
                    type="primary"
                    onClick={captureAllTransactions}
                    className={classes.buttonAllCapture}
                >
                    Capture All
                </Button>
            </h1>

            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={paymentHistory}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                }}
                onRow={(record) => ({
                    onClick: (event) => {
                        // Check if the clicked target is within the MAC address column
                        const target = event.target.closest('[data-column="mac"]');
                        if (target) {
                            setOpen(true);
                            getDevice(record.mac);
                        }
                    },
                })}
                scroll={{ x: "max-content" }}
                size="small"
            />

        <DeviceManagerDrawer
            visible={open}
            onClose={() => {
                setOpen(false);
                setCurrent(null);
            }}
            userInfo={current}
        />
    </div>
    );
}

export default PaymentHistory;