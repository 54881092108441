import React, { useState, useEffect } from "react";
import { Area } from '@ant-design/plots';
import REQUESTS from "../../../api/requests";
import classes from "../index.module.scss";

const COLOR_PLATE_10 = [
  '#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16', '#E8684A', 
  '#6DC8EC', '#9270CA', '#FF9D4D', '#269A99', '#FF99C3',
];

export default function PaymentStatistics({ dateType = "year", date }) {
  const [data, setData] = useState([]);

  const config = {
    data,
    xField: dateType === "year" ? 'month' : "day",
    yField: 'count',
    seriesField: 'name',
    color: COLOR_PLATE_10,
    point: {
      shape: ({ category }) => category === 'Gas fuel' ? 'square' : 'circle',
      style: ({ year }) => ({
        r: Number(year) % 4 ? 0 : 4,
      }),
    },
    tooltip: {
      showMarkers: true,
      shared: true,
      customContent: (title, items) => {
        const total = items.reduce((sum, item) => sum + item.data.count, 0);
        return `<div style="padding: 10px;">
                  <strong>Day: ${title} | Total: ${total}</strong>
                  ${items.map(item => `<div style="color:${item.color}; margin:10px 0;     text-transform: capitalize;">${item.name}: ${item.data.count}</div>`).join('')}
                </div>`;
      },
    },
    areaStyle: {
      fillOpacity: 0.2,
    },
    smooth: true,
    legend: {
      position: 'top-right',
    },
    label: {
      position: 'middle',
      style: {
        fill: '#8c8c8c',
        opacity: 0.8,
        fontSize: 0,
      },
      formatter: (datum) => `${datum.count}`,
    },
    responsive: true,
    padding: 'auto',
  };

  const getChart = () => {
    const query = {
      data_type: dateType,
      date,
      type: "platform",
    };

    function collback(data) {
      if (Array.isArray(data)) {
        data = data.map((obj) => {
          return { ...obj, count: parseInt(obj.count), day: obj.day?.toString() };
        });
        setData(data);
      } else {
        const clone = { ...data };
        const newData = [];
        for (let platform in clone) {
          for (let value of clone[platform]) {
            newData.push({
              name: platform,
              count: parseInt(value.count),
              day: dateType === 'month' || dateType === 'week' ? value.day.toString() : value.month,
            });
          }
        }
        const _newData = newData.sort((a, b) => a.day - b.day);
        setData(_newData);
      }
    }

    REQUESTS.STATISTICS.CART_PAYMENTS(collback, (err) => { }, query);
  };

  useEffect(() => {
    getChart();
  }, [date, dateType]);

  return (
    <div className={classes["container"]}>
      <h2 className={classes["title"]}>Payments</h2>
      <Area {...config} style={{ height: 350 }} />
    </div>
  );
}
